<template>
  <div>

    <div class="deta">
      <p class="title">
        订单信息
      </p>

      <el-row>
        <el-col :span="6">
          <div class="order">
            <p>订单编号</p>
            <p>{{ deta.orderNo }}</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="order">
            <p>商品名称</p>
            <p>{{ deta.productName }}</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="order">
            <p>商品类别</p>
            <p>{{ deta.productCategory != null ? $store.state.category.filter((item) => { return item.dictKey == deta.productCategory })[0].dictValue : "" }}</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="order">
            <p style="border-right: 1px solid rgba(187, 187, 187, 100);">商品价格</p>
            <p style="border-right: 1px solid rgba(187, 187, 187, 100);">{{ deta.productPrice }}</p>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="8">
          <div class="pay_info">
            <p>购买人：{{ deta.name }}</p>
            <p>下单时间：{{ deta.orderTime }}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="pay_info">
            <p>手机号：{{ deta.phoneNo }}</p>
            <p>付款时间：{{ deta.payTime }}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="pay_info">
            <p>交易流水号：{{ deta.transNo }}</p>
            <p>支付方式：{{deta.orderType == '0' ? '微信支付' : '现金支付'}}</p>
          </div>
        </el-col>
        <el-col :span="8">

        </el-col>
      </el-row>


      <div class="content">
        <div class="content_right" v-if="$route.query.state == 0" >
          <div class="talk_craft_box">
            <div class="talk_craft_search">
              <el-input
                  @change="params(talk)"
                  placeholder="请输入内容"
                  prefix-icon="el-icon-search"
                  v-model="talk.question">
              </el-input>
            </div>

            <div class="talk_craft_list">
              <div>
                <el-collapse v-model="talkActive" accordion>
                  <el-collapse-item v-for="(item,index) in talkList" :key="index"
                                    :title="item.question" :name="index">
                    <div class="talk_collapse" v-html="item.answer" ></div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </div>
        </div>
        <div class="content_left">
<!--          就诊人-->
          <div class="deta_form">
            <el-form ref="info_form"
                     :model="info_form"
                     :rules="info_form_rules"
                     label-width="80px"
                     :hide-required-asterisk="true"
                     :disabled="this.disabled" >
              <div class="title">
                就诊人
              </div>
              <el-form-item label="姓名" prop="name">
                <el-input v-model="info_form.name" clearable placeholder="请输入姓名"></el-input>
              </el-form-item>
              <el-form-item label="性别" prop="gender" >
                <el-input v-model="info_form.gender" clearable placeholder="请输入性别"></el-input>
              </el-form-item>
              <el-form-item label="手机号" prop="phoneNo">
                <el-input v-model="info_form.phoneNo" clearable placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="身份证号" prop="idCardNo">
                <el-input v-model="info_form.idCardNo" clearable placeholder="请输入身份证号"></el-input>
              </el-form-item>
            </el-form>
          </div>
<!--          预约时间-->
          <div class="deta_form" v-for="(item,index) in form_data" :key="index">
            <el-form ref="form"
                     :model="item"
                     :rules="rules"
                     label-width="80px"
                     :hide-required-asterisk="true"
                     :disabled="item.isDisabled == '1'" >
              <div class="title">
                <p class="title-product">{{ item.productCategory != null ? $store.state.category.filter((items) => { return  items.dictKey == item.productCategory })[0].dictValue : null }}</p>
                <p v-if="item.status == 0" class="warning">待确认</p>
                <p v-if="item.status == 1" class="primary">待检查</p>
                <p v-if="item.status == 2" class="success">已完成</p>
                <p v-if="item.status == 3" class="danger">已失效</p>
              </div>
              <el-form-item label="预约时间" prop="dateTimes">
                <el-date-picker
                    clearable
                    v-model="item.dateTimes"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="验血时间" prop="bloodTestDate" v-if="item.productCategory != 0" >
                <el-date-picker
                    v-model="item.bloodTestDate"
                    type="date"
                    clearable
                    :picker-options="pickerOptions0"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="预约日期" prop="appointmentDate">
                <el-date-picker
                    v-model="item.appointmentDate"
                    type="date"
                    clearable
                    :picker-options="pickerOptions0"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="预约时段" prop="appointmentPeriod">
                <el-select v-model="item.appointmentPeriod" clearable placeholder="请选择">
                  <el-option
                      v-for="items in item.periodDict"
                      :key="items.dictKey"
                      :label="items.dictValue"
                      :value="items.dictKey">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="预约地点" prop="appointmentHospital"  >
                <!--            :disabled="item.hospitalDict.length == 1"-->
                <el-select v-model="item.appointmentHospital" clearable placeholder="请选择" >
                  <el-option
                      v-for="items in item.hospitalDict"
                      :key="items.dictKey"
                      :label="items.dictValue"
                      :value="items.dictKey">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>

      </div>

      <div class="button">
        <el-button size="small" @click="Cancel()" >取消</el-button>
        <el-button size="small" @click="onSubmit(form_data)" type="primary" >确认</el-button>
      </div>

<!--      <div class="ready">-->
<!--        <p>胃肠道准备：</p>-->
<!--        <el-image-->
<!--            style="width: 100px; height: 100px"-->
<!--            :src="url"-->
<!--            :preview-src-list="srcList">-->
<!--        </el-image>-->
<!--      </div>-->

    </div>


  </div>
</template>

<script>
export default {
  name: "ServiceDeta",
  data() {
    return{
      url: null,
      srcList: [],
      deta: {},
      disabled: false,
      // 就诊人
      info_form: {
        name: null,
        gender: null,
        phoneNo: null,
        idCardNo: null,
        serviceId: null,
      },
      info_form_rules: {
        name: [
          { required: true, message: '请输入就诊人姓名', trigger: 'blur' }
        ],
        gender: [
          { required: true, message: '请输入就诊人性别', trigger: 'blur' }
        ],
        phoneNo: [
          { required: true, message: '请输入就诊人手机号', trigger: 'blur' },
          { pattern: /^((13[0-9])|(14[0,1,4-9])|(15[0-3,5-9])|(16[2,5,6,7])|(17[0-8])|(18[0-9])|(19[0-3,5-9]))\d{8}$/, message: '手机号不正确' }
        ],
        idCardNo: [
          { required: true, message: '请输入就诊人身份证号', trigger: 'blur' },
          { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '身份证号不正确' }
        ],
      },
      // 体检产品
      form_data: [],
      rules: {
        dateTimes: [
          { required: true, message: '请选择预约时间', trigger: 'blur' }
        ],
        appointmentDate: [
            { required: true, message: '请选择预约日期', trigger: 'blur' }
        ],
        appointmentPeriod: [
            { required: true, message: '请选择预约时段', trigger: 'blur' }
        ],
        appointmentHospital: [
            { required: true, message: '请选择预约地点', trigger: 'blur' }
        ]
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now() + 1
        }
      },
      // 话术
      talk: {
        dialogType: '0', // 话术类型
        hot: true, // 常用问题
        question: null, // 搜索问题
      },
      talkActive: '1', // 展开问题
      talkList: []
    }
  },
  watch: {
    info_form: {
      handler(newVal) {
        if(/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(newVal.idCardNo)) {
          if((newVal.idCardNo[newVal.idCardNo.length-2] % 2) == 0 ) {
            this.info_form.gender= "女";
          }else {
            this.info_form.gender= "男";
          }
        }else {
          this.info_form.gender= "--";
        }
      },
      deep: true
    }
  },
  created() {

    console.log(this.$store.state.category);

    // 数据
    this.get_data(this.$route.query.orderNo);
    // 话术列表
    this.getTalkList(this.talk);
  },
  mounted() {

  },
  methods: {
    // 话术搜索
    params(e) {
      e.hot= false;
      this.getTalkList(e);
    },
    /* 获取话术列表 */
    getTalkList(y) {
      this.$get("cs/dialog",y)
          .then(res => {
            if(res) {
              // console.log(res);
              this.talkList= res.data;
            }
          })
    },
    onSubmit(formName) {


      this.$refs.info_form.validate((valid) => {
        if(valid) {
          this.$refs.form.forEach((item,index) => {
            item.validate((valid) => {
              if(valid) {

                if((formName.length-1) == index) {

                  for (let i = 0; i < formName.length; i++) {
                    if(formName[i].dateTimes.length != 0) {
                      formName[i].appointmentStartDate= formName[i].dateTimes[0];
                      formName[i].appointmentEndDate= formName[i].dateTimes[1];

                      if(formName[i].bloodTestDate != null) {
                        if(new Date(formName[i].appointmentDate).getTime() < new Date(formName[i].bloodTestDate).getTime()) {
                          this.$message.error("预约日期不可小于验血时间");
                          return;
                        }
                      }

                    }
                  }

                  this.info_form["appointmentConfirmList"]= formName;

                  this.$post("cs/appoint/confirm",this.info_form)
                      .then(res => {
                        if(res) {
                          console.log(res);

                          this.$router.push({
                            path: "/MakeService",
                            query: {
                              activeName: this.$route.query.state
                            }
                          })

                        }
                      })

                }

              }
            })
          })
        }
      })

    },
    Cancel() {
      this.$router.push({
        path: "/MakeService",
        query: {
          activeName: this.$route.query.state
        }
      })
    },
    // 详情
    get_deta(y) {
      this.$get("cs/appoint",y,true)
        .then(res => {
          if(res) {
            console.log(res.data);

            res.data.appointmentInfoList.forEach((item,index) => {

              if(item.appointmentStartDate) {
                let arr = [item.appointmentStartDate,item.appointmentEndDate];
                this.$set(this.form_data[index],"dateTimes",arr);
              }else {
                this.$set(this.form_data[index],"dateTimes",[]);
              }

              this.form_data[index].appointmentDate = item.appointmentDate;
              this.form_data[index].appointmentPeriod= item.appointmentPeriod;
              this.form_data[index].appointmentHospital= item.appointmentHospital;
              this.form_data[index].bloodTestDate= item.bloodTestDate;
              this.form_data[index].status= item.status;
              this.form_data[index]["appointId"]= item.appointId;
              this.form_data[index]["isDisabled"]= item.isDisabled;
              if(item.isDisabled == '1') {
                this.disabled = true;
              }
            })

            this.deta= res.data;


            console.log(this.form_data);

            // 就诊人信息
            this.info_form.name= res.data.name;
            this.info_form.phoneNo= res.data.phoneNo;
            this.info_form.idCardNo= res.data.idCardNo;
            // 时段时间
            this.info_form.serviceId= res.data.serviceId;

          }
        })
    },
    // 数据
    get_data(y) {
      this.$get("cs/appoint/data",y,true)
        .then(res => {
          if(res) {
            console.log(res)

            res.data.forEach((item) => {
              item["appointmentDate"]= null;
            })

            this.form_data= res.data;

            // 详情
            this.get_deta(this.$route.query.id);
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
 @import "../../style/Service/ServiceDeta.less";
</style>
